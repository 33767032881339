import dayjs from 'dayjs'
import { getDateString } from '../../utils/date'
import { getRandomNumber } from '../../utils/math'
import api from '../index.js'
import {
  getEndpointData,
  getEndpointDataRow,
  setEndpointData
} from './index.js'

const client_id = 20607

const screenshot = '/dont-eat-me.webp'

const exampleDomains = [
  ' demoexample.com',
  ' demoprovider.com',
  ' demoproviderexample.com',
  ' exampledemo.com',
  ' exampleexample.com',
  ' exampleprovider.com',
  ' providerdemo.com',
  ' providerexample.com'
]

const getUserTokenPayload = () =>
  JSON.parse(atob(localStorage.loginToken.split('.')[1]))

const getUser = () => {
  const { sub } = getUserTokenPayload()

  return {
    created_at: '',
    display_name: `${sub.firstname} ${sub.lastname}`,
    id: sub.id,
    last_action: '',
    last_login: '',
    status: 0,
    type: sub.administrator ? 'admin' : 'user'
  }
}

const refreshDates = (rows, keys) => {
  const oldest = dayjs('1900-01-01')

  const latest = Object.fromEntries(
    keys.map((key) => [
      key,
      rows.reduce((acc, row) => {
        if (row[key] === undefined) return acc
        const d = dayjs(row[key])
        return d.isAfter(acc) ? d : acc
      }, oldest)
    ])
  )

  const now = dayjs()
  for (const row of rows) {
    for (const key of keys) {
      if (['', null, undefined].includes(row[key]) || latest[key] === oldest) {
        continue
      }
      const d = dayjs(row[key])
      const diff = latest[key].diff(d)
      row[key] = now.subtract(diff, 'ms').toISOString()
    }
  }

  return rows
}

/**
 * alias: 'serviceName' // (optional)
 * Use name and config of serviceName. Used do avoid v1/v2 service duplication
 *
 * generateEntry: (...args) => entry // (optional)
 * Generates a service entry, mostly used by getStaticData()
 *
 * getDefaults: async (payload) => entry // (required)
 * Receives the body of a create$/post request, adds default values to it
 *
 * getStaticData: async (Request) => {} // (optional)
 * Is called to retrieve data (instead of actual api endpoint)
 *
 * includes: ['string', 'string', …] // (optional)
 * Relations to always include via jsonapi when fetching data
 *
 * key: 'string' // (required)
 * Key to retrieve the ID with
 *
 * regex: RegExp // (required)
 * Regular Expression Object to match url with
 *
 * skipParams: bool // (optional)
 * skips setting any query params to index calls when retrieving data (like
 * offset, limit)
 *
 * transform: async (rows, id, URL) => rows // (optional)
 * Transform data retrieved from indexeddb before it's submitted to app
 *
 * update: (rows) => rows // (optional)
 * Transform data before it's stored in indexeddb (refresh timestamps etc.)
 */

const mockedServices = {
  blacklistsglobal: {
    key: 'blacklist_id',
    regex: /^\/emma\/blacklist/,
    update: (rows) => refreshDates(rows, ['created_at'])
  },
  campaignaction_stats: {
    generateEntry: (campagne_id) => [
      {
        aggregated: {
          smtp_server_stats: Object.fromEntries(
            exampleDomains.map((domain) => {
              const new_msg = getRandomNumber(800, 1200)
              return [
                domain,
                {
                  domain,
                  new_msg,
                  accepted: new_msg,
                  deferred: 0,
                  failed: 0,
                  expired: 0,
                  deferred_throttle: 0
                }
              ]
            })
          )
        },
        campagne_id
      }
    ],
    getStaticData: (r) => {
      const url = new URL(r.url)
      const serviceConfig = mockedServices.campaignaction_stats
      const match = url.pathname.match(serviceConfig.regex)
      return serviceConfig.generateEntry(Number(match[1]))
    },
    key: 'campagne_id',
    regex: /^\/emma\/campaign\/(\d+)\/stats/,
    transform: async (data, id) => {
      const serviceName = 'campaignaction_stats'
      const serviceConfig = mockedServices[serviceName]
      if (!data && id) {
        data = await serviceConfig.getStaticData({
          url: `http://domain/emma/campaign/${id}/stats`
        })
        await setEndpointData(serviceName, serviceConfig, data)
      }
      return data
    }
  },
  campaignaction_teststats: {
    generateEntry: (email, timestamp) => {
      const [localpart, domain] = email.split('@')
      return {
        head: '',
        html: `\n<p>Showing data for: </p>\n\n\n\n<table class=\"quick_data\">\n\n<tr class=\"lb\" width=\"100%\">\n<td><b>injection_time</b></td>\n<td><b>localpart</b></td>\n<td><b>domain</b></td>\n<td><b>smtp_result_code</b></td>\n<td><b>smtp_result_time</b></td>\n<td><b>status_messages</b></td>\n<td><b>outmtaid</b></td>\n</tr>\n\n<tr class=\"lb\">\n<td nowrap>${timestamp}</td>\n<td>${localpart}</td>\n<td>${domain}</td>\n<td>accepted</td>\n<td nowrap>${timestamp}</td>\n<td></td>\n<td>smtp2-20_testmail_mc</td>\n</tr>\n</table>\n`,
        success: 1
      }
    },
    getStaticData: async (r) => {
      const url = new URL(r.url)
      const serviceConfig = mockedServices.campaignaction_teststats
      const match = url.pathname.match(serviceConfig.regex)
      const timestamp = dayjs()
        .subtract(1, 'hour')
        .format('YYYY-MM-DD HH:mm:ss')
      const [{ email }] = await getEndpointData('testsubscribers')
      return {
        campagne_id: Number(match[1]),
        rows: [serviceConfig.generateEntry(email, timestamp)]
      }
    },
    key: 'campagne_id',
    regex: /^\/emma\/campaign\/(\d+)\/teststats/
  },
  campaigns: {
    alias: 'campaignsv2',
    regex: /^\/emma\/campaign/
  },
  mtareport: {
    getStaticData: (r) => {
      const url = new URL(r.url)
      const match = url.pathname.match(mockedServices.mtareport.regex)
      const row = mockedServices.campaignaction_stats.generateEntry(
        Number(match[1])
      )
      return row
    },
    key: 'campagne_id',
    regex: /^\/v2\/emma\/client\/\d+\/campaign\/(\d+)\/mta-report/,
    transform: async (data, id) => {
      const serviceName = 'campaignaction_stats'
      const serviceConfig = mockedServices[serviceName]
      if (!data && id) {
        data = await serviceConfig.getStaticData({
          url: `http://domain/emma/campaign/${id}/stats`
        })
        await setEndpointData(serviceName, serviceConfig, data)
      }
      return data
    }
  },
  reportsview: {
    generateEntry: (startdate) => {
      const nb_campaigns = Math.ceil(Math.random() * 10)
      const nb_send = Math.ceil(nb_campaigns * 10)
      const nb_delivered = Math.ceil(nb_send * 0.98)
      const nb_open = Math.ceil(nb_delivered * 0.9)
      return {
        nb_campaigns,
        nb_click: Math.ceil(nb_open * 0.8),
        nb_complaint: Math.ceil(nb_delivered * 0.05),
        nb_delivered,
        nb_hardbounce: Math.ceil((nb_send - nb_delivered) / 4),
        nb_open,
        nb_otherbounce: Math.ceil((nb_send - nb_delivered) / 4),
        nb_send,
        nb_softbounce: Math.ceil((nb_send - nb_delivered) / 2),
        nb_unsubscribe: Math.ceil(nb_delivered * 0.05),
        startdate
      }
    },
    getStaticData: (r) => {
      const serviceConfig = mockedServices.reportsview
      const url = new URL(r.url)

      const endDate = dayjs(url.searchParams.get('enddate') ?? undefined)
      const match = url.pathname.match(serviceConfig.regex)
      const campagne_id = Number(match[1])

      let startDate = url.searchParams.has('startdate')
        ? dayjs(url.searchParams.get('startdate'))
        : endDate.subtract(7, 'day')

      const rows = []
      while (!startDate.isAfter(endDate)) {
        rows.push(serviceConfig.generateEntry(startDate.format('YYYY-MM-DD')))
        startDate = startDate.add(1, 'day')
      }

      return { campagne_id, rows }
    },
    key: 'campagne_id',
    regex:
      /^\/v2\/emma\/client\/\d+\/membertable\/\d+\/campaign\/(\d+)\/report:view/,
    transform: async (rows = [], _id, url) => {
      const endDate = dayjs(url.searchParams.get('enddate') ?? undefined)
      let startDate = url.searchParams.has('startdate')
        ? dayjs(url.searchParams.get('startdate'))
        : endDate.subtract(7, 'day')

      const data = []
      const newRows = []

      const serviceName = 'reportsview'
      const serviceConfig = mockedServices[serviceName]

      while (!startDate.isAfter(endDate)) {
        const d = startDate.format('YYYY-MM-DD')

        let row = (rows.rows ?? rows).find((r) => r.startdate === d)
        if (!row) {
          row = serviceConfig.generateEntry(d)
          newRows.push(row)
        }

        data.push(row)
        startDate = startDate.add(1, 'day')
      }

      if (newRows.length > 0) {
        const match = url.pathname.match(serviceConfig.regex)
        const campagne_id = Number(match[1])
        await setEndpointData(serviceName, serviceConfig, [
          {
            campagne_id,
            rows: [...rows, ...newRows]
          }
        ])
      }

      return data
    }
  },
  campaignsv2: {
    getDefaults: async (payload) => {
      // { label: 'Normal', value: 1 },
      // { label: 'Automation', value: 2 }, // recurring
      // { label: 'Autogenerated', value: 3 },
      // { label: 'Transactional', value: 10 },
      // { label: 'Double-Opt-In', value: 13 }

      const c = {
        client_id,
        combined_last_count: getDateString(),
        manager_id: JSON.parse(atob(localStorage.loginToken.split('.')[1])).sub
          .id,
        member_table_id: 1,
        screenshot: [{ url_large: screenshot }],
        ...payload
      }

      if (c.ctype === 1) {
        c.combined_count_status = 2 // int<2 = still_counting
      }

      const { data: lists } = await api.segments
        .findAll$({ filters: { list_id: c.list_id } })
        .toPromise()
      const volume = lists.reduce((acc, l) => acc + l.nb_unique, 0)
      c.combined_volume = volume
      c.estimated_volume = volume

      const domain = await api.domains.findOne$(c.domain_id).toPromise()
      c.domain = [domain]

      const mailclass = await api.mailclasses
        .findOne$(c.mailclass_id)
        .toPromise()
      c.mailclass = [mailclass]

      const message = await api.templates.findOne$(c.message_id).toPromise()
      c.message = [message]

      return c
    },
    includes: ['domain', 'mailclass', 'message', 'screenshot'],
    key: 'campagne_id',
    regex: /^\/v2\/emma\/client\/\d+\/campaign/,
    update: (rows) => refreshDates(rows, ['combined_last_count', 'startdate'])
  },
  motd: {
    key: 'id',
    regex: /^\/motd/,
    update: (rows) => rows.filter((row) => row.id === 143)
  },
  placeholders: {
    getDefaults: () => ({ is_public: false }),
    key: 'module_id',
    regex: /^\/emma\/placeholder/
  },
  sendvolume: {
    getStaticData: () =>
      Array(12)
        .fill(null)
        .map((_, i) => ({
          invoice_month: dayjs().subtract(i, 'month').format('YYYY-MM'),
          volume: getRandomNumber(100_000, 400_000)
        })),
    key: 'invoice_month',
    regex: /^\/v2\/emma\/client\/\d+\/send-volume/
  },
  subscribers: {
    getDefaults: () => ({
      datejoin: getDateString(undefined, { iso8601: true })
    }),
    key: 'member_id',
    regex: /^\/v2\/emma\/client\/\d+\/membertable\/\d+\/member/,
    update: (rows) =>
      refreshDates(rows, ['created_at', 'datejoin', 'updated_at'])
  },
  templatepreview: {
    generateEntry: (template) => ({
      from: 'Demo',
      html: template.body_long,
      message_id: template.message_id,
      subject: template.subject,
      text: template.body_text
    }),
    getStaticData: async (r) => {
      const serviceConfig = mockedServices.templatepreview
      const url = new URL(r.url)
      const match = url.pathname.match(serviceConfig.regex)
      const id = Number(match[1])
      const template = await getEndpointDataRow('templates', id)
      return serviceConfig.generateEntry(template)
    },
    key: 'message_id',
    regex: /^\/v2\/emma\/client\/\d+\/message\/(\d+)\/render/,
    skipParams: true,
    transform: async (body, _id, url) => {
      if (body) return body
      const serviceConfig = mockedServices.templatepreview
      const match = url.pathname.match(serviceConfig.regex)
      const id = Number(match[1])
      const template = await getEndpointDataRow('templates', id)
      return serviceConfig.generateEntry(template)
    }
  },
  templates: {
    getDefaults: (payload) => ({
      campaign: [],
      is_tracked: true,
      manager_id: JSON.parse(atob(localStorage.loginToken.split('.')[1])).sub
        .id,
      member_table_id: 1,
      message_type: 3,
      nb_locked: 0,
      screenshot: [{ url_large: screenshot }],
      url: [
        ...payload.body_long.matchAll(/href(?!\s+)?=(?!\s+)?['"](.*?)['"]/gm)
      ].map((m, i) => ({
        id: m.index,
        is_active: true,
        name: `URL${i + 1}`,
        url: m[1]
      })),
      user: [getUser()]
    }),
    includes: ['campaign', 'emmajob', 'screenshot', 'url', 'user'],
    key: 'message_id',
    regex: /^\/emma\/message/
  },
  testsubscribers: {
    getDefaults: () => ({ active: true }),
    key: 'member_id',
    regex: /^\/emma\/testaddress/,
    update: (rows) =>
      refreshDates(rows, ['created_at', 'datejoin', 'updated_at'])
  },
  transactionaldoireports: {
    getStaticData: (r) => {
      const params = new URLSearchParams(r.url)
      const endDate = dayjs(params.get('end_date') ?? undefined)

      let startDate = params.has('start_date')
        ? dayjs(params.get('start_date'))
        : endDate.subtract(7, 'day')

      let entries = getRandomNumber(200, 250)
      const data = []

      while (!startDate.isAfter(endDate)) {
        const imported = getRandomNumber(160, 180)
        entries = entries + getRandomNumber(-10, 10)
        data.push({
          date: startDate.format('YYYY-MM-DD'),
          entries,
          imported
        })
        startDate = startDate.add(1, 'day')
      }

      return data
    },
    key: 'date',
    regex: /^\/v2\/emma\/client\/\d+\/membertable\/\d+\/doi-report/,
    transform: async (rows, _id, url) => {
      const numDays = dayjs(url.searchParams.get('end_date'))
        .add(1, 'day')
        .diff(url.searchParams.get('start_date'), 'day')

      if (rows.length !== numDays) {
        const serviceName = 'transactionaldoireports'
        const serviceConfig = mockedServices[serviceName]

        rows = await serviceConfig.getStaticData({ url: url.toString() })
        await setEndpointData(serviceName, serviceConfig, rows)
      }

      return rows.reduce((acc, { date, ...data }) => {
        acc[date] = data
        return acc
      }, {})
    }
  },
  transactionalreports: {
    generateEntry: (date) => ({
      date,
      sent: 0,
      opened: getRandomNumber(140, 160),
      proxy_opened: getRandomNumber(4, 8),
      clicked: 0,
      unsubscribed: getRandomNumber(0, 15),
      complained: getRandomNumber(1, 5),
      soft_bounced: 0,
      hard_bounced: 0,
      other_bounced: 0
    }),
    getStaticData: (r) => {
      const url = new URL(r.url)
      const endDate = dayjs(url.searchParams.get('end_date') ?? undefined)

      let startDate = url.searchParams.has('start_date')
        ? dayjs(url.searchParams.get('start_date'))
        : endDate.subtract(7, 'day')

      const data = []
      while (!startDate.isAfter(endDate)) {
        data.push(
          mockedServices.transactionalreports.generateEntry(
            startDate.format('YYYY-MM-DD')
          )
        )
        startDate = startDate.add(1, 'day')
      }

      return data
    },
    key: 'date',
    regex: /^\/transactional-report/,
    transform: async (_rows, _id, url) => {
      const endDate = dayjs(url.searchParams.get('end_date') ?? undefined)
      let startDate = url.searchParams.has('start_date')
        ? dayjs(url.searchParams.get('start_date'))
        : endDate.subtract(7, 'day')

      const data = []
      const rows = await getEndpointData('transactionalreports')
      const newRows = []

      const serviceName = 'transactionalreports'
      const serviceConfig = mockedServices[serviceName]

      while (!startDate.isAfter(endDate)) {
        const d = startDate.format('YYYY-MM-DD')

        let row = rows.find((r) => r.date === d)
        if (!row) {
          row = serviceConfig.generateEntry(d)
          newRows.push(row)
        }

        data.push(row)
        startDate = startDate.add(1, 'day')
      }

      if (newRows.length > 0) {
        await setEndpointData(serviceName, serviceConfig, newRows)
      }

      return data.reduce((acc, { date, ...data }) => {
        acc[date] = data
        return acc
      }, {})
    }
  },
  usersettings: {
    key: 'user_id',
    regex: /^\/user\/(\d+)\/settings/,
    skipParams: true
  },
  user: {
    getDefaults: (row) => ({
      clients: [{ client_id, client_name: 'EMMA Demo' }],
      isDemo: true,
      password: undefined,
      roles: [{ id: row.role, name: row.role }],
      settings: { client_id }
    }),
    includes: ['clients', 'settings'],
    key: 'id',
    regex: /^\/user/,
    update: (rows) => {
      const { sub } = getUserTokenPayload()
      return rows.filter((u) => u.id === sub.id || u.isDemo)
    }
  }
}

export default mockedServices
