import { Suspense, lazy, useCallback, useEffect } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import Cheats from './components/Cheats'
import ErrorBoundary from './components/ErrorBoundary'
import Loading from './components/Loading'
import Modals from './components/Modals'
import Notifications from './components/Notifications'
import ScrollToTop from './components/ScrollToTop'
import useNotifications from './hooks/useNotifications'
import useUser from './hooks/useUser'
import './scss/style.scss'

const AcceptAgb = lazy(() => import('./views/AcceptAgb'))
const AcceptBav = lazy(() => import('./views/AcceptBav'))
const AgbEdit = lazy(() => import('./views/AgbEdit'))
const Agbs = lazy(() => import('./views/Agbs'))
const AlertEdit = lazy(() => import('./views/AlertEdit'))
const Alerts = lazy(() => import('./views/Alerts'))
const AppShell = lazy(() => import('./views/AppShell'))
const ApphostEdit = lazy(() => import('./views/ApphostEdit'))
const Apphosts = lazy(() => import('./views/Apphosts'))
const BavEdit = lazy(() => import('./views/BavEdit'))
const Bavs = lazy(() => import('./views/Bavs'))
const BlacklistGlobal = lazy(() => import('./views/BlacklistGlobal'))
const BlacklistGlobalAdd = lazy(() => import('./views/BlacklistGlobalAdd'))
const BlacklistGlobalImports = lazy(
  () => import('./views/BlacklistGlobalImports')
)
const BlacklistImport = lazy(() => import('./views/BlacklistImport'))
const BlacklistImportIndex = lazy(() => import('./views/BlacklistImportIndex'))
const BlacklistInternal = lazy(() => import('./views/BlacklistInternal'))
const BlacklistInternalAdd = lazy(() => import('./views/BlacklistInternalAdd'))
const BlacklistInternalExcludes = lazy(
  () => import('./views/BlacklistInternalExcludes')
)
const BlacklistsCampaign = lazy(() => import('./views/BlacklistsCampaign'))
const CampaignEdit = lazy(() => import('./views/CampaignEdit'))
const CampaignMonitoring = lazy(() => import('./views/CampaignMonitoring'))
const CampaignStart = lazy(() => import('./views/CampaignStart'))
const CampaignTest = lazy(() => import('./views/CampaignTest'))
const Campaigns = lazy(() => import('./views/Campaigns'))
const CampaignsCalendar = lazy(() => import('./views/CampaignsCalendar'))
const Categories = lazy(() => import('./views/Categories'))
const CategoryEdit = lazy(() => import('./views/CategoryEdit'))
const ClientAdd = lazy(() => import('./views/ClientAdd'))
const ClientSettings = lazy(() => import('./views/ClientSettings'))
const Clients = lazy(() => import('./views/Clients'))
const ClusterMailclassEdit = lazy(() => import('./views/ClusterMailclassEdit'))
const ClusterMailclasses = lazy(() => import('./views/ClusterMailclasses'))
const Dashboard = lazy(() => import('./views/Dashboard'))
const DataUploadSqlite = lazy(() => import('./views/DataUploadSqlite'))
const DatasourceEdit = lazy(() => import('./views/DatasourceEdit'))
const Datasources = lazy(() => import('./views/Datasources'))
const DomainEdit = lazy(() => import('./views/DomainEdit'))
const DomainSetupAdd = lazy(() => import('./views/DomainSetupAdd'))
const DomainSetupIndex = lazy(() => import('./views/DomainSetupIndex'))
const Domains = lazy(() => import('./views/Domains'))
const DownloadTemplateEdit = lazy(() => import('./views/DownloadTemplateEdit'))
const DownloadTemplates = lazy(() => import('./views/DownloadTemplates'))
const Downloads = lazy(() => import('./views/Downloads'))
const EspReplacerEdit = lazy(() => import('./views/EspReplacerEdit'))
const EspReplacers = lazy(() => import('./views/EspReplacers'))
const EspSenderEdit = lazy(() => import('./views/EspSenderEdit'))
const EspSenders = lazy(() => import('./views/EspSenders'))
const EspSettings = lazy(() => import('./views/EspSettings'))
const FileManagerIndex = lazy(() => import('./views/FileManagerIndex'))
const Forbidden = lazy(() => import('./views/Forbidden'))
const GuidedTourEdit = lazy(() => import('./views/GuidedTourEdit'))
const GuidedTours = lazy(() => import('./views/GuidedTours'))
const Help = lazy(() => import('./views/Help'))
const HelpIndex = lazy(() => import('./views/HelpIndex'))
const IpAllocation = lazy(() => import('./views/IpAllocation'))
const IpAllocationEdit = lazy(() => import('./views/IpAllocationEdit'))
const Login = lazy(() => import('./views/Login'))
const MailclassEdit = lazy(() => import('./views/MailclassEdit'))
const Mailclasses = lazy(() => import('./views/Mailclasses'))
const MessageEdit = lazy(() => import('./views/MessageEdit'))
const MessageIndex = lazy(() => import('./views/MessageIndex'))
const MtaStatsExternal = lazy(() => import('./views/MtaStatsExternal'))
const NotFound = lazy(() => import('./views/NotFound'))
const PlaceholderEdit = lazy(() => import('./views/PlaceholderEdit'))
const Placeholders = lazy(() => import('./views/Placeholders'))
const ProviderMailclassTemplateEdit = lazy(
  () => import('./views/ProviderMailclassTemplateEdit')
)
const ProviderMailclassTemplates = lazy(
  () => import('./views/ProviderMailclassTemplates')
)
const RecurringCampaignEdit = lazy(
  () => import('./views/RecurringCampaignEdit')
)
const RecurringCampaigns = lazy(() => import('./views/RecurringCampaigns'))
const RecurringReport = lazy(() => import('./views/RecurringReport'))
const RecurringReports = lazy(() => import('./views/RecurringReports'))
const Report = lazy(() => import('./views/Report'))
const ReportGroupsIndex = lazy(() => import('./views/ReportGroupsIndex'))
const Reports = lazy(() => import('./views/Reports'))
const ReportsCompare = lazy(() => import('./views/ReportsCompare'))
const RoleEdit = lazy(() => import('./views/RoleEdit'))
const Roles = lazy(() => import('./views/Roles'))
const ScdRuleEdit = lazy(() => import('./views/ScdRuleEdit'))
const ScdRules = lazy(() => import('./views/ScdRules'))
const SegmentEdit = lazy(() => import('./views/SegmentEdit'))
const SegmentPreview = lazy(() => import('./views/SegmentPreview'))
const Segments = lazy(() => import('./views/Segments'))
const SendVolume = lazy(() => import('./views/SendVolume'))
const SqlTemplateEdit = lazy(() => import('./views/SqlTemplateEdit'))
const SqlTemplates = lazy(() => import('./views/SqlTemplates'))
const SubReports = lazy(() => import('./views/SubReports'))
const SubscriberEdit = lazy(() => import('./views/SubscriberEdit'))
const SubscriberHistory = lazy(() => import('./views/SubscriberHistory'))
const SubscriberImport = lazy(() => import('./views/SubscriberImport'))
const SubscriberImportIndex = lazy(
  () => import('./views/SubscriberImportIndex')
)
const Subscribers = lazy(() => import('./views/Subscribers'))
const TemplateCatalog = lazy(() => import('./views/TemplateCatalog'))
const TemplateEdit = lazy(() => import('./views/TemplateEdit'))
const Templates = lazy(() => import('./views/Templates'))
const TestHistoryExternal = lazy(() => import('./views/TestHistoryExternal'))
const TestSubscriberEdit = lazy(() => import('./views/TestSubscriberEdit'))
const TestSubscribers = lazy(() => import('./views/TestSubscribers'))
const Testbed = lazy(() => import('./views/Testbed'))
const TestmailboxEdit = lazy(() => import('./views/TestmailboxEdit'))
const Testmailboxes = lazy(() => import('./views/Testmailboxes'))
const TransactionalEdit = lazy(() => import('./views/TransactionalEdit'))
const Transactionals = lazy(() => import('./views/Transactionals'))
const Translations = lazy(() => import('./views/Translations'))
const UserEdit = lazy(() => import('./views/UserEdit'))
const Users = lazy(() => import('./views/Users'))
const WebFormEdit = lazy(() => import('./views/WebFormEdit'))
const WebForms = lazy(() => import('./views/WebForms'))

export const routes = [
  {
    name: 'nav.campaigns',
    path: '/campaigns'
  },
  {
    component: Campaigns,
    name: 'nav.campaigns_archive',
    path: '/campaigns/archive',
    permissions: ['EmmaCampaign:get']
  },
  {
    component: CampaignsCalendar,
    name: 'nav.campaigns_calendar',
    path: '/campaigns/calendar',
    permissions: ['EmmaCampaign:get']
  },
  {
    component: Categories,
    name: 'services.categories_pl',
    path: '/campaigns/categories',
    permissions: ['EmmaCategoryValue:get']
  },
  {
    component: CategoryEdit,
    name: 'nav.category_edit',
    path: '/campaigns/categories/edit/:id',
    permissions: ['EmmaCategoryValue:get']
  },
  {
    component: CampaignEdit,
    name: 'nav.campaigns_edit',
    path: '/campaigns/edit/:id',
    permissions: [
      'EmmaCampaign:get',
      'EmmaDomain:get',
      'EmmaMailclass:get',
      'EmmaList:get'
    ]
  },
  {
    component: CampaignMonitoring,
    name: 'nav.monitoring',
    path: '/campaigns/monitoring/:id',
    permissions: ['EmmaCampaign:get', 'IPCI:get']
  },
  {
    component: Reports,
    name: 'nav.reports',
    path: '/campaigns/reports',
    permissions: ['EmmaReport:get']
  },
  {
    component: Report,
    name: 'nav.campaigns_report',
    path: '/campaigns/reports/:id',
    permissions: ['EmmaReport:get']
  },
  {
    component: ReportGroupsIndex,
    name: 'nav.reportgroups',
    path: '/campaigns/reportgroups',
    permissions: ['EmmaReport:get', 'ReportingGroup:get']
  },
  {
    component: ReportsCompare,
    name: 'nav.compare_reports',
    path: '/campaigns/reports/compare/:id',
    permissions: ['EmmaReport:get']
  },
  {
    component: SubReports,
    name: 'nav.subreports',
    path: '/campaigns/reports/subreports/:id',
    permissions: ['EmmaList:get', 'EmmaReport:get', 'EmmaSubreportCampaign:get']
  },
  {
    component: SendVolume,
    name: 'services.sendvolume',
    path: '/billing/sendvolume',
    permissions: ['SendVolume:get']
  },
  {
    component: CampaignStart,
    name: 'nav.campaigns_start',
    path: '/campaigns/start/:id',
    permissions: ['EmmaCampaign:patch']
  },
  {
    component: CampaignTest,
    name: 'nav.campaigns_test',
    path: '/campaigns/test/:id',
    permissions: [
      'EmmaCampaign:get',
      'EmmaTestCampaign:get',
      'EmmaTestAddress:get',
      'TestGroup:get'
    ]
  },
  {
    component: Dashboard,
    name: 'nav.dashboard',
    path: '/dashboard',
    permissions: ['Dashboard:get']
  },
  {
    component: Downloads,
    name: 'services.downloads_pl',
    path: '/downloads'
  },
  {
    component: EspReplacers,
    name: 'services.espreplacers',
    path: '/external-esps/replacers/:esp',
    permissions: ['EmmaEspReplacer:get']
  },
  {
    component: EspReplacerEdit,
    name: 'nav.espreplacers_edit',
    path: '/external-esps/replacers/:esp/edit/:id',
    permissions: ['EmmaEspReplacer:get']
  },
  {
    component: EspSenders,
    name: 'services.espsenders_pl',
    path: '/external-esps/senders/:esp',
    permissions: ['EmmaEspSendfromid:get']
  },
  {
    component: EspSenderEdit,
    name: 'nav.espsenders_edit',
    path: '/external-esps/senders/:esp/edit/:id',
    permissions: ['EmmaEspSendfromid:get']
  },
  {
    component: EspSettings,
    name: 'services.espsettings_pl',
    path: '/external-esps/settings/:esp',
    permissions: ['EmmaEspLogin:get', 'EmmaEspSendfromid:get']
  },
  {
    component: HelpIndex,
    name: 'nav.help_index',
    path: '/help',
    permissions: ['Help:get']
  },
  {
    component: Help,
    name: 'services.help',
    path: '/help/:id',
    permissions: ['Help:get']
  },
  {
    component: MessageIndex,
    name: 'nav.messages',
    path: '/messages'
  },
  {
    component: MessageEdit,
    name: 'nav.messages_edit',
    path: '/messages/edit/:id'
  },
  {
    component: RecurringCampaigns,
    name: 'nav.recurring_campaigns',
    path: '/recurring/archive',
    permissions: ['EmmaCampaign:get']
  },
  {
    component: RecurringCampaignEdit,
    name: 'nav.recurring_campaigns_edit',
    path: '/recurring/edit/:id',
    permissions: [
      'EmmaCampaign:get',
      'EmmaDomain:get',
      'EmmaMailclass:get',
      'EmmaList:get'
    ]
  },
  {
    component: RecurringReports,
    name: 'nav.recurring_reports',
    path: '/recurring/reports',
    permissions: ['EmmaReport:get']
  },
  {
    component: RecurringReport,
    name: 'nav.recurring_report',
    path: '/recurring/reports/:id',
    permissions: ['EmmaReport:get']
  },
  {
    name: 'nav.settings',
    path: '/settings'
  },
  {
    component: Alerts,
    name: 'nav.alerts',
    path: '/settings/alerts',
    permissions: ['Alerts:get']
  },
  {
    component: AlertEdit,
    name: 'nav.alerts_edit',
    path: '/settings/alerts/edit/:id',
    permissions: ['Alerts:get', 'EmmaDomain:get']
  },
  {
    component: ClientSettings,
    name: 'settings.default_settings',
    path: '/settings/clients',
    permissions: ['EmmaClient:get']
  },
  {
    component: Datasources,
    name: 'services.datasources_pl',
    path: '/settings/datasources',
    permissions: ['EmmaMemberTable:get']
  },
  {
    component: DatasourceEdit,
    name: 'nav.datasources_edit',
    path: '/settings/datasources/edit/:id',
    permissions: ['EmmaMemberTable:get']
  },
  {
    component: DomainSetupIndex,
    name: 'nav.domainsetup',
    path: '/settings/domain-setup',
    permissions: ['DomainSetupStatus:get']
  },
  {
    component: DomainSetupAdd,
    name: 'nav.domainsetup_add',
    path: '/settings/domain-setup/add',
    permissions: ['DomainSetupStatus:post']
  },
  {
    component: Domains,
    name: 'nav.domains',
    path: '/settings/domains',
    permissions: ['EmmaDomain:get']
  },
  {
    component: DomainEdit,
    name: 'nav.domains_edit',
    path: '/settings/domains/edit/:id',
    permissions: ['EmmaDomain:get']
  },
  {
    component: Mailclasses,
    name: 'nav.mailclasses',
    path: '/settings/mailclasses',
    permissions: ['EmmaMailclass:get', 'EmmaMemberTable:get']
  },
  {
    component: MailclassEdit,
    name: 'nav.mailclasses_edit',
    path: '/settings/mailclasses/edit/:id',
    permissions: ['EmmaMailclass:get', 'EmmaMemberTable:get']
  },
  {
    component: ProviderMailclassTemplateEdit,
    name: 'nav.pmtemplates_edit',
    path: '/settings/pmtemplates/edit/:id',
    permissions: ['EmmaProviderMailclassTemplate:get']
  },
  {
    component: ProviderMailclassTemplates,
    name: 'nav.pmtemplates',
    path: '/settings/pmtemplates',
    permissions: ['EmmaProviderMailclassTemplate:get']
  },
  {
    component: Testmailboxes,
    name: 'nav.testmailboxes',
    path: '/settings/testmailboxes',
    permissions: ['EmmaImap:get']
  },
  {
    component: TestmailboxEdit,
    name: 'nav.testmailboxes_edit',
    path: '/settings/testmailboxes/edit/:id',
    permissions: ['EmmaImap:get']
  },
  {
    name: 'nav.recipients',
    path: '/subscribers'
  },
  {
    component: SubscriberHistory,
    name: 'nav.subscriber_activity',
    path: '/subscribers/activity/:id',
    permissions: ['EmmaMember:get']
  },
  {
    component: Subscribers,
    name: 'nav.subscribers',
    path: '/subscribers/archive'
  },
  {
    component: BlacklistsCampaign,
    name: 'nav.blacklistscampaign',
    path: '/subscribers/blacklistscampaign',
    permissions: ['EmmaMultiBlacklist:get']
  },
  {
    component: BlacklistImportIndex,
    name: 'misc.all_imports',
    path: '/subscribers/blacklistscampaign/imports',
    permissions: ['DataUpload:get']
  },
  {
    component: BlacklistGlobal,
    name: 'nav.blacklistsglobal',
    path: '/subscribers/blacklistsglobal',
    permissions: ['EmmaBlacklist:get']
  },
  {
    component: BlacklistGlobalAdd,
    name: 'nav.blacklistsglobal_add',
    path: '/subscribers/blacklistsglobal/add',
    permissions: ['EmmaBlacklist:patch', 'EmmaBlacklist:post']
  },
  {
    component: BlacklistGlobalImports,
    name: 'misc.all_imports',
    path: '/subscribers/blacklistsglobal/imports',
    permissions: ['DataUpload:get']
  },
  {
    component: BlacklistImport,
    name: 'blacklists.import_campaign',
    path: '/subscribers/blacklistsimport',
    permissions: ['DataUpload:post']
  },
  {
    component: BlacklistImport,
    name: 'blacklists.import_global',
    path: '/subscribers/blacklistsimportglobal',
    permissions: ['DataUpload:post']
  },
  {
    component: SubscriberImportIndex,
    name: 'nav.dataupload',
    path: '/subscribers/dataupload',
    permissions: ['DataUpload:get']
  },
  {
    component: SubscriberImport,
    name: 'nav.dataupload_new',
    path: '/subscribers/dataupload/edit/:id',
    permissions: ['DataUpload:post']
  },
  {
    component: SubscriberEdit,
    name: 'nav.subscribers_edit',
    path: '/subscribers/edit/:id',
    permissions: ['EmmaMember:get', 'EmmaMemberTable:get']
  },
  {
    component: Segments,
    name: 'nav.segments',
    path: '/subscribers/segments',
    permissions: ['EmmaList:get']
  },
  {
    component: SegmentEdit,
    name: 'nav.segments_edit',
    path: '/subscribers/segments/edit/:id',
    permissions: ['EmmaList:get', 'EmmaMemberTable:get']
  },
  {
    component: SegmentPreview,
    name: 'nav.segment_preview',
    path: '/subscribers/segments/preview/:id',
    permissions: ['EmmaList:get']
  },
  {
    component: TestSubscribers,
    name: 'nav.testsubscribers',
    path: '/subscribers/testsubscribers',
    permissions: ['EmmaTestAddress:get']
  },
  {
    component: TestSubscriberEdit,
    name: 'nav.testsubscribers_edit',
    path: '/subscribers/testsubscribers/edit/:id',
    permissions: ['EmmaMemberTable:get', 'EmmaTestAddress:get']
  },
  {
    component: WebForms,
    name: 'nav.webforms',
    path: '/subscribers/web-forms',
    permissions: ['WebForms:get']
  },
  {
    component: WebFormEdit,
    name: 'nav.webforms_edit',
    path: '/subscribers/web-forms/edit/:id',
    permissions: ['WebForms:get']
  },
  {
    name: 'nav.system',
    path: '/system'
  },
  {
    component: Agbs,
    name: 'services.agbs_pl',
    path: '/system/agbs'
  },
  {
    component: AgbEdit,
    name: 'nav.agbs_edit',
    path: '/system/agbs/edit/:id'
  },
  {
    component: Apphosts,
    name: 'nav.apphosts',
    path: '/system/apphosts'
  },
  {
    component: ApphostEdit,
    name: 'nav.apphosts_edit',
    path: '/system/apphosts/edit/:id'
  },
  {
    component: Bavs,
    name: 'services.bavs_pl',
    path: '/system/bavs'
  },
  {
    component: BavEdit,
    name: 'nav.bavs_edit',
    path: '/system/bavs/edit/:id'
  },
  {
    component: BlacklistInternal,
    name: 'services.blacklistsinternal',
    path: '/system/blacklistsinternal'
  },
  {
    component: BlacklistInternalAdd,
    name: 'nav.blacklistsinternal_add',
    path: '/system/blacklistsinternal/add'
  },
  {
    component: BlacklistInternalExcludes,
    name: 'nav.blacklistsinternal_excludes',
    path: '/system/blacklistsinternal/excludes'
  },
  {
    component: Clients,
    name: 'nav.clients',
    path: '/system/clients'
  },
  {
    component: ClientAdd,
    name: 'nav.clients_add',
    path: '/system/clients/add'
  },
  {
    component: ClusterMailclasses,
    name: 'nav.clustermailclasses',
    path: '/system/clustermailclasses'
  },
  {
    component: ClusterMailclassEdit,
    name: 'nav.clustermailclasses_edit',
    path: '/system/clustermailclasses/edit/:id'
  },
  {
    component: DownloadTemplates,
    name: 'services.downloadtemplates_pl',
    path: '/system/downloadtemplates'
  },
  {
    component: DownloadTemplateEdit,
    name: 'nav.downloadtemplates_edit',
    path: '/system/downloadtemplates/edit/:id'
  },
  {
    component: GuidedTours,
    name: 'services.guidedtours_pl',
    path: '/system/guided-tours'
  },
  {
    component: GuidedTourEdit,
    name: 'nav.guidedtour_edit',
    path: '/system/guided-tours/edit/:id'
  },
  {
    component: IpAllocation,
    name: 'services.ipallocation_pl',
    path: '/system/ipallocation'
  },
  {
    component: IpAllocationEdit,
    name: 'nav.ipallocation_new',
    path: '/system/ipallocation/edit/:id'
  },
  {
    component: ScdRules,
    name: 'services.scdrules_pl',
    path: '/system/scd-rules'
  },
  {
    component: ScdRuleEdit,
    name: 'nav.scdrules_edit',
    path: '/system/scd-rules/edit/:id'
  },
  {
    component: Roles,
    name: 'nav.roles',
    path: '/system/roles'
  },
  {
    component: RoleEdit,
    name: 'nav.roles_edit',
    path: '/system/roles/edit/:id'
  },
  {
    component: SqlTemplates,
    name: 'services.sqltemplates_pl',
    path: '/system/sqltemplates'
  },
  {
    component: SqlTemplateEdit,
    name: 'nav.sqltemplates_edit',
    path: '/system/sqltemplates/edit/:id'
  },
  {
    component: Testbed,
    name: 'nav.testbed',
    path: '/system/testbed'
  },
  {
    component: DataUploadSqlite,
    name: 'nav.dataupload_new',
    path: '/system/testbed/dataupload',
    permissions: ['DataUpload:post']
  },
  {
    component: Translations,
    name: 'services.translations_pl',
    path: '/system/translations'
  },
  {
    name: 'nav.templates',
    path: '/templates'
  },
  {
    component: Templates,
    name: 'nav.templates_archive',
    path: '/templates/archive',
    permissions: ['EmmaMessage:get', 'EmmaPlaceholder:get']
  },
  {
    component: TemplateCatalog,
    name: 'templates.catalog',
    path: '/templates/catalog',
    permissions: ['BeePluginCatalog:get']
  },
  {
    component: TemplateEdit,
    name: 'nav.templates_edit',
    path: '/templates/edit/:id',
    permissions: [
      'EmmaMemberTable:get',
      'EmmaMessage:get',
      'EmmaPlaceholder:get',
      'FileManager:delete',
      'FileManager:get',
      'FileManager:post'
    ]
  },
  {
    component: FileManagerIndex,
    name: 'nav.file_manager',
    path: '/templates/file-manager'
  },
  {
    component: Placeholders,
    name: 'nav.placeholders',
    path: '/templates/placeholders',
    permissions: ['EmmaPlaceholder:get']
  },
  {
    component: PlaceholderEdit,
    name: 'nav.placeholders_edit',
    path: '/templates/placeholders/edit/:id',
    permissions: ['EmmaMemberTable:get', 'EmmaPlaceholder:get']
  },
  {
    name: 'services.transactionals_pl',
    path: '/transactionals'
  },
  {
    component: Transactionals,
    name: 'services.transactionals_pl',
    path: '/transactionals/archive',
    permissions: ['EmmaTransactional:get']
  },
  {
    component: TransactionalEdit,
    name: 'nav.transactionals_edit',
    path: '/transactionals/edit/:id',
    permissions: ['EmmaTransactional:get']
  },
  {
    component: Users,
    name: 'nav.users',
    path: '/users',
    permissions: ['User:get']
  },
  {
    component: UserEdit,
    name: 'nav.users',
    path: '/users/edit/:id',
    permissions: ['User:get']
  },
  {
    component: UserEdit,
    name: 'users.usersettings',
    path: '/usersettings'
  },
  {
    component: NotFound,
    path: '*'
  }
]

const App = () => {
  const { addNotification, removeNotification } = useNotifications()
  const { t } = useTranslation()
  const { hasPermissions, loading } = useUser()

  const handleOffline = useCallback(() => {
    if (window.document.hasFocus()) {
      const id = addNotification({
        message: t('misc.offline_msg'),
        title: t('misc.offline'),
        type: 'warning'
      })
      window.addEventListener(
        'online',
        () => {
          removeNotification(id)
          addNotification({
            message: t('misc.online_msg'),
            title: t('misc.online'),
            type: 'success'
          })
        },
        { once: true }
      )
    }
  }, [addNotification, removeNotification, t])

  useEffect(() => {
    window.addEventListener('offline', handleOffline)
    return () => window.removeEventListener('offline', handleOffline)
  }, [handleOffline])

  useEffect(() => {
    if (
      !window.ReadableStream ||
      !window.TransformStream ||
      !window.WritableStream
    ) {
      addNotification({
        message: t('misc.browser_compat_msg'),
        title: t('misc.browser_compat'),
        type: 'danger'
      })
    }
  }, [addNotification, t])

  const isLoading = loading > 0

  return (
    <ErrorBoundary>
      <HelmetProvider>
        <Suspense
          fallback={
            <div className="p-3">
              <Loading setCounter={true} />
            </div>
          }
        >
          <Router>
            <ScrollToTop>
              <Routes>
                <Route element={<AppShell />} path="/">
                  {routes
                    .filter((r) => r.component)
                    .map(({ component: Component, path, permissions }) => (
                      <Route
                        element={
                          hasPermissions(permissions) ? (
                            <Component isLoading={isLoading} />
                          ) : (
                            <Forbidden />
                          )
                        }
                        key={path}
                        path={path}
                      />
                    ))}
                </Route>

                <Route
                  element={<AcceptAgb isLoading={isLoading} />}
                  path="/accept-agb"
                />

                <Route
                  element={<AcceptBav isLoading={isLoading} />}
                  path="/accept-bav"
                />

                <Route
                  element={<Login isLoading={isLoading} />}
                  path="/login"
                />

                <Route
                  element={
                    <div className="bg-white p-3">
                      <MtaStatsExternal isLoading={isLoading} />
                    </div>
                  }
                  path="/mtastats/:id"
                />

                <Route
                  element={
                    <div className="px-3 px-md-5 py-3 py-md-4">
                      <ReportsCompare isLoading={isLoading} salesview />
                    </div>
                  }
                  path="/compare-reports/:id"
                />

                <Route
                  element={
                    <div className="px-3 px-md-5 py-3 py-md-4">
                      <Report isLoading={isLoading} salesview />
                    </div>
                  }
                  path="/salesview/:id"
                />

                <Route
                  element={
                    <div className="px-3 px-md-5 py-3 py-md-4">
                      <SubReports isLoading={isLoading} salesview />
                    </div>
                  }
                  path="/salesview/subreports/:id"
                />

                <Route
                  element={
                    <div className="bg-white px-md-5 py-3 py-md-4">
                      <TestHistoryExternal isLoading={isLoading} />
                    </div>
                  }
                  path="/testhistory/:id"
                />
              </Routes>
            </ScrollToTop>
          </Router>
        </Suspense>
        <Cheats />
        <Modals />
        <Notifications />
      </HelmetProvider>
    </ErrorBoundary>
  )
}

export default App
