import { addNotifications } from '../hooks/useNotifications'
import i18n from '../i18n'

export const translateMsg = (msg) => {
  if (/decoding.*token/.test(msg)) {
    return i18n.t('errors.token_invalid')
  }

  if (msg === 'Domain is already being setup.') {
    return i18n.t('settings.domain_already_setup')
  }

  if (msg === 'Faulty X-Revision value provided') {
    return i18n.t('errors.revision')
  }

  if (/SQLSTATE\[(08006|HY000)\]/.test(msg)) {
    return i18n.t('errors.sql_connection')
  }

  if (/token.*expired/.test(msg)) {
    return i18n.t('errors.token_expired')
  }

  if (/Unique violation/.test(msg)) {
    const match = msg.match(/DETAIL.*\((.*?)\)=\((.*?)\)/)
    if (match) {
      return i18n.t('errors.unique_violation', {
        key: match[1],
        value: match[2]
      })
    }
  }

  if (msg) {
    return msg
  }

  return `${i18n.t('errors.generic_message')}.`
}

export const errorHandler = (error) => {
  console.error(error)

  const message = error?.message || i18n.t('errors.generic_message')

  const notification = {
    title: i18n.t('errors.generic_message'),
    body: translateMsg(message),
    type: 'danger'
  }

  return addNotifications([notification])
}
