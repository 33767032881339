import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import i18n from '../i18n'

dayjs.extend(utc)

const getDateset = (
  { defaultLimit = 7, direction = 'backwards', unit = 'date' } = {}
) =>
  (opts = {}) => {
    const limit = (opts.limit || defaultLimit) - 1
    const set = [dayjs(opts.startDate)]

    if (direction === 'backwards') {
      while (
        set[0].isBefore(
          set[set.length - 1].set(unit, set[set.length - 1][unit]() + limit)
        )
      ) {
        set.push(set[set.length - 1].set(unit, set[set.length - 1][unit]() - 1))
      }
    }

    if (direction === 'forwards') {
      while (
        set[set.length - 1].isBefore(set[0].set(unit, set[0][unit]() + limit))
      ) {
        set.push(set[set.length - 1].set(unit, set[set.length - 1][unit]() + 1))
      }
    }

    return set.map(d => d.toDate())
  }

export const getLastXDays = getDateset()
export const getLastXHours = getDateset({ unit: 'hour', defaultLimit: 24 })
export const getLastXMonths = getDateset({ unit: 'month', defaultLimit: 12 })
export const getNextXDays = getDateset({ direction: 'forwards' })

export const getDate = (dateString, opts = {}) => {
  const date = opts.isUTC ? dayjs.utc(dateString) : dayjs(dateString)
  return date.toDate()
}

export const getDateString = (
  timestamp,
  { dateonly, iso8601, isUTC, noyear, showseconds, timeonly, ...otherOpts } = {}
) => {
  timestamp = typeof timestamp === 'string'
    ? timestamp.replace(/\.[0-9]+/, '')
    : timestamp === null
    ? undefined
    : timestamp

  const date = isUTC ? dayjs.utc(timestamp) : dayjs(timestamp)

  if (!date.isValid()) {
    return timestamp
  }

  if (iso8601) {
    return date.format('YYYY-MM-DDTHH:mm:ss')
  }

  const config = { ...otherOpts }

  if (!noyear && !timeonly) {
    config.year = '2-digit'
  }

  if (!dateonly) {
    config.hour = '2-digit'
    config.minute = '2-digit'
  }

  if (!timeonly) {
    config.day = '2-digit'
    config.month = '2-digit'
  }

  if (showseconds) {
    config.second = '2-digit'
  }

  return new Intl.DateTimeFormat(i18n.language, config).format(date.toDate())
}

export const getDuration = (date1, date2) => {
  const second = 1000
  const minute = 60 * second
  const hour = 60 * minute
  const day = 24 * hour

  const duration = []

  let diff = new Date(date2).getTime() - new Date(date1).getTime()

  if (diff % day < diff) {
    duration.push(`${Math.floor(diff / day)}d`)
    diff = diff % day
  }

  if (diff % hour < diff) {
    duration.push(`${Math.floor(diff / hour)}h`)
    diff = diff % hour
  }

  if (diff % minute < diff) {
    duration.push(`${Math.floor(diff / minute)}m`)
    diff = diff % minute
  }

  if (diff % second < diff) {
    duration.push(`${Math.floor(diff / second)}s`)
    diff = diff % second
  }

  return duration.join(' ')
}
