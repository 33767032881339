import _isObject from 'lodash/isObject'
import { empty, forkJoin, from, of } from 'rxjs'
import { expand, map, reduce, scan, switchMap } from 'rxjs/operators'
import { getUserSettings } from '../../hooks/useUser'
import { checkArgs } from '../../utils/args'
import client from './client'

export const flattenItem = (
  { attributes = {}, relationships = {}, ...rest },
  includes = []
) => {
  const newItem = {
    ...rest,
    ...attributes
  }

  for (const relation of Object.keys(relationships)) {
    if (_isObject(newItem[relation])) continue

    newItem[relation] = relationships[relation].data.map((rel) => {
      const resolved = includes.find(
        (inc) => inc.id === rel.id && inc.type === rel.type
      )
      if (!resolved) return rel
      const { data, ...rest } = resolved
      return { ...data, ...rest }
    })
  }

  return newItem
}

export const getEndpoint = (endpoint, args = {}) => {
  args = {
    ...getUserSettings(),
    ...args
  }

  for (const arg of Object.keys(args)) {
    endpoint = endpoint.replace(`{${arg}}`, args[arg])
  }

  if (endpoint.match(/{[\w|\d]+}/g) !== null) {
    throw new Error(`Not enough arguments provided for endpoint ${endpoint}`)
  }

  return endpoint
}

const transformData = (data) =>
  Object.keys(data).reduce((acc, key) => {
    acc[key] = data[key] === '' ? null : data[key]
    return acc
  }, {})

const adapter = (endpoint, customDefaults = {}) => {
  const defaults = { label: 'name', primaryKey: 'id', ...customDefaults }

  const create$ = (data, args = {}, options = {}) => {
    checkArgs({ data })

    const { params, ...extraOpts } = options
    let path = getEndpoint(endpoint, args)

    if (params) {
      const urlParams = new window.URLSearchParams()

      for (const [key, value] of Object.entries(params)) {
        if (Array.isArray(value)) {
          for (const val of value) {
            urlParams.append(`${key}[]`, val)
          }
          continue
        }

        urlParams.append(key, value)
      }

      path = `${path}?${urlParams.toString()}`
    }

    if (localStorage.obfuscate === 'true') {
      return of(data)
    }

    return client({
      body: transformData(data),
      method: 'POST',
      multipart: defaults.multipart,
      path,
      ...extraOpts
    })
  }

  const find$ = (options, args = {}, includes = []) => {
    const allowedParams = [
      'abuseAfter',
      'abuseBefore',
      'all_membertables',
      'category',
      'client_id',
      'collection',
      'dateunjoinAfter',
      'dateunjoinBefore',
      'distinct',
      'doi_link',
      'domain',
      'email',
      'end_date',
      'enddate',
      'global',
      'globalblacklist',
      'hardbounceAfter',
      'hardbounceBefore',
      'include_unsubscribed',
      'is_blocklisted',
      'member_table_id',
      'onlyactive',
      'openedAfter',
      'openedBefore',
      'page',
      'pagesize',
      'search',
      'start_date',
      'startdate',
      'startdateAfter',
      'startdateBefore',
      'status',
      'superadminview',
      'table',
      'template_type',
      'test',
      'type',
      'visibility'
    ]

    const params = new window.URLSearchParams()

    for (const include of includes) {
      params.append('explicitly_included[]', include)
    }

    if (options) {
      for (const param of allowedParams) {
        if (options[param] !== undefined) {
          params.set(param, options[param])
        }
      }
    }

    if (options?.filters) {
      for (const [key, values] of Object.entries(options.filters)) {
        if (!values) continue

        for (const val of values) {
          params.append(`${key}[]`, val)
        }
      }
    }

    if (options?.searchterm) {
      params.set('search', options.searchterm)
    }

    if (options?.secret) {
      params.set('secret', options.secret)
    }

    if (options?.sort) {
      options.sort.desc && params.append('desc', options.sort.desc)
      options.sort.key && params.append('orderby', options.sort.key)
    }

    if (options?.superadminview) {
      params.set('superadminview', 'true')
    }

    if (options?.limit !== null && !params.get('limit')) {
      params.set(
        'limit',
        options?.limit ?? getUserSettings()?.items_per_page ?? 10
      )
    }

    if (
      options?.skip !== undefined &&
      options?.skip !== null &&
      !params.get('offset')
    ) {
      params.set('offset', options.skip)
    }

    const queryParams = params.toString().replace(/=null/g, '=%00')

    const transform = (json) => {
      const data = Array.isArray(json) ? json : json.data ? json.data : [json]

      return {
        data: data.map((item) => flattenItem(item, json.included)),
        limit: json.pagination?.limit ?? options?.limit ?? null,
        skip: json.pagination?.offset ?? options?.skip ?? null,
        sort: options?.sort ?? { desc: null, sort: null },
        total: json.pagination?.total ?? data.length ?? null
      }
    }

    const observer = client({
      blob: options?.blob,
      credentials: options?.credentials,
      ignoreCache: options?.isPolling || options?.ignoreCache,
      ignoreLoadingState: options?.isPolling || options?.ignoreLoadingState,
      jsonapi: options?.jsonapi ?? defaults.jsonapi,
      path: `${getEndpoint(endpoint, args)}?${queryParams}`,
      transform
    })

    if (localStorage.obfuscate === 'true') {
      return from(import('../../utils/obfuscate.js')).pipe(
        switchMap((mod) => observer.pipe(map(mod.default(endpoint))))
      )
    }

    return observer
  }

  const findAll$ = ({ stream, ...options } = {}, ...args) => {
    const reducer = (acc, res) => {
      if (res.data) {
        acc = acc || { data: [] }
        return {
          ...res,
          data: [...acc.data, ...res.data],
          limit: res.total,
          skip: 0
        }
      }

      if (Array.isArray(res)) {
        acc = acc || []
        return [...acc, ...res]
      }

      throw new Error(`Unknown response signature: ${res}}`)
    }

    return find$({ limit: 50, ...options }, ...args).pipe(
      expand(({ limit, skip, sort, total }) =>
        limit + skip < total
          ? find$({ ...options, limit, skip: skip + limit, sort }, ...args)
          : empty()
      ),
      stream ? scan(reducer) : reduce(reducer)
    )
  }

  const findOne$ = (id, args, includes = [], extraOpts = {}) => {
    checkArgs({ id })

    const params = new window.URLSearchParams()

    const allowedParams = [
      'email',
      'end_date',
      'secret',
      'start_date',
      'test',
      'unsub'
    ]

    for (const param of allowedParams) {
      if (extraOpts[param] !== undefined) {
        params.set(param, extraOpts[param])
      }
    }

    const transform = (res) =>
      res.data
        ? flattenItem(
            {
              ...(Array.isArray(res.data) ? res.data[0] : res.data)
            },
            res.included
          )
        : res

    for (const include of includes) {
      params.append('explicitly_included[]', include)
    }

    const observer = client({
      path: `${getEndpoint(endpoint, args)}${id}?${params.toString()}`,
      transform,
      ...extraOpts,
      jsonapi: extraOpts?.jsonapi ?? defaults.jsonapi
    })

    if (localStorage.obfuscate === 'true') {
      return from(import('../../utils/obfuscate.js')).pipe(
        switchMap((mod) => observer.pipe(map(mod.default(endpoint))))
      )
    }

    return observer
  }

  const patch$ = (id, data, args, extraOpts = {}) => {
    checkArgs({ id, data })

    if (localStorage.obfuscate === 'true') {
      return client({
        path: getEndpoint(endpoint, args) + id,
        ...extraOpts,
        jsonapi: extraOpts?.jsonapi ?? defaults.jsonapi
      })
    }

    return client({
      body: transformData(data),
      method: 'PATCH',
      multipart: defaults.multipart,
      path: getEndpoint(endpoint, args) + id,
      ...extraOpts
    })
  }

  const remove$ = (id, args, extraOpts = {}) => {
    checkArgs({ id })

    const params = new window.URLSearchParams()

    const allowedParams = ['test']

    for (const param of allowedParams) {
      if (extraOpts[param] !== undefined) {
        params.set(param, extraOpts[param])
      }
    }

    const ids = id.values ? [...id] : [id]

    return forkJoin(
      ids.map((id) =>
        client({
          method: 'DELETE',
          path: `${getEndpoint(endpoint, args)}${id}?${params.toString()}`,
          ...extraOpts
        })
      )
    )
  }

  const update$ = (id = '', data, args, extraOpts = {}) => {
    checkArgs({ id, data })

    if (localStorage.obfuscate === 'true') {
      return client({
        path: getEndpoint(endpoint, args) + id,
        ...extraOpts,
        jsonapi: extraOpts?.jsonapi ?? defaults.jsonapi
      })
    }

    return client({
      body: transformData(data),
      method: 'PUT',
      multipart: defaults.multipart,
      path: getEndpoint(endpoint, args) + id,
      ...extraOpts
    })
  }

  return {
    create$,
    defaults,
    endpoint,
    find$,
    findAll$,
    findOne$,
    patch$,
    remove$,
    update$
  }
}

export default adapter
