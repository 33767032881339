import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@beyond/reactstrap-emma'
import useNotifications from '../hooks/useNotifications'
import IconX from './Icons/X'

const getColors = (type) => {
  switch (type) {
    case 'info':
    case 'danger':
    case 'success':
      return { bg: type, fg: 'light' }

    case 'warning':
      return { bg: type, fg: 'dark' }

    default:
      return { bg: 'secondary', fg: 'dark' }
  }
}

const Notification = ({
  body = '',
  buttons = [],
  id,
  style,
  title = '',
  type = 'info'
}) => {
  type = type === 'default' ? 'secondary' : type

  const { notifications, removeNotification, removeTimer } = useNotifications()
  const { t } = useTranslation()

  const { bg, fg } = getColors(type)

  return (
    <div className="animation-fade-down-in ml-auto show">
      <div
        className={`bg-${bg} d-inline-block notification m-2 position-relative p-3 rounded text-${fg}`}
        onFocus={() => removeTimer(id)}
        onMouseOver={() => removeTimer(id)}
        style={{
          '--bg-color': `var(--${bg})`,
          '--fg-color': `var(--${fg})`,
          maxWidth: 350,
          ...style
        }}
      >
        <div
          className={clsx(
            'align-items-center close-buttons d-flex p-1 position-absolute right-0 top-0',
            notifications.length > 1 && 'multiple'
          )}
        >
          <button
            className={`bg-${bg} border font-sm mr-1 p-0 text-${fg}`}
            onClick={() => removeNotification()}
            type="button"
          >
            <span className="mx-1">{t('misc.close_all')}</span>
          </button>

          <button
            className={`bg-${bg} border font-sm p-0 text-${fg}`}
            data-border-color={`var(--${fg})`}
            onClick={() => removeNotification(id)}
            type="button"
          >
            <IconX
              className="d-block"
              style={{ fill: `var(--${fg})`, height: 16 }}
            />
          </button>
        </div>

        <p className="font-weight-bold mb-2">{title}</p>

        <p
          className="font-sm m-0"
          // biome-ignore lint/security/noDangerouslySetInnerHtml:
          dangerouslySetInnerHTML={{ __html: body }}
        />

        {buttons.length > 0 && (
          <div className="mt-2 mx-n2 text-right">
            {buttons.map((b) => (
              <Button
                className={`mx-1 border-${fg}`}
                color={fg}
                key={b.label}
                onClick={b.action}
                outline
                size="sm"
              >
                {b.label}
              </Button>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

Notification.propTypes = {
  body: PropTypes.string.isRequired,
  buttons: PropTypes.array,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string
}

export default Notification
