import { sortAlphabetically } from '../../utils/data'
import { getDateString } from '../../utils/date'
import { flattenItem } from '../brm'
import mockedServices from './mocked-services'

export const allowedEndpoints = [
  /\/auth\/login/,
  /\/v2\/emma\/client\/\d+\/bav-confirm/,
  /\/v2\/emma\/client\/\d+\/message:generate-subject/,
  /\/validator/
]

const deleteEndpointDataRow = (serviceName, id) =>
  getDb().then((db) =>
    new Promise((resolve, reject) => {
      const tx = db.transaction(serviceName, 'readwrite')
      tx.onerror = (e) => reject(e.target.error)

      const store = tx.objectStore(serviceName)
      const request = store.delete(id)
      request.onerror = (e) => reject(e.target.error)
      request.onsuccess = (e) => resolve(e.target.result)
    }).finally(() => db.close())
  )

const extractBody = async (req) => {
  const decoderStream = new TextDecoderStream()
  const decompressionStream = new DecompressionStream('gzip')

  let data = null
  let str = ''
  const writableStream = new WritableStream({
    write(chunk) {
      str += chunk
    },
    close() {
      data = JSON.parse(str)
    }
  })

  if (req.body) {
    await req.body
      .pipeThrough(decompressionStream)
      .pipeThrough(decoderStream)
      .pipeTo(writableStream)
  }

  return data
}

const filterRows = (obj) => {
  const {
    search = '',
    startdateAfter,
    startdateBefore,
    ...filters
  } = Object.fromEntries(
    Object.entries(obj)
      .map(([key, val]) => [
        key.endsWith('[]') ? key.substring(0, key.length - 2) : key,
        val === '\u0000' ? null : val
      ])
      .filter(([key]) => key !== 'included' && key !== 'explicitly_included')
  )

  return (row) =>
    Object.values(row)
      .map((val) => (val ?? '').toString().toLowerCase())
      .some((val) => val.toLowerCase().includes(search)) &&
    Object.entries(filters).every(
      ([key, val]) => (row[key] || '').toString() === (val || '').toString()
    )
}

const getData = async (req, origin, pathname) => {
  const [serviceName, serviceConfig] = getServiceConfig(pathname)
  const u = new URL(`${origin}${pathname}`)
  const match = u.pathname.match(serviceConfig.regex)

  let data = []
  try {
    if (match?.[1]) {
      data = await getEndpointDataRow(serviceName, Number(match[1]))
    } else {
      data = await getEndpointData(serviceName)
    }
  } catch {
    let json = { pagination: { offset: -50, total: Number.POSITIVE_INFINITY } }

    if (!serviceConfig.skipParams) {
      u.searchParams.set('limit', 50)

      for (const [key, val] of new URL(req.url).searchParams) {
        if (key === 'end_date' || key === 'start_date') {
          u.searchParams.set(key, val)
        }

        if (key === 'enddate' || key === 'startdate') {
          u.searchParams.set(key, val)
        }

        if (key === 'explicitly_included[]' || key === 'included[]') {
          u.searchParams.append(key, val)
        }
      }
    }

    while (data.length < json.pagination.total) {
      if (!serviceConfig.skipParams) {
        u.searchParams.set('offset', json.pagination.offset + 50)
      }

      if (serviceConfig.includes) {
        for (const inc of serviceConfig.includes) {
          u.searchParams.append('explicitly_included[]', inc)
        }
      }

      const r = new Request(u.toString(), {
        headers: {
          accept: req.headers.get('accept'),
          authorization: req.headers.get('authorization')
        }
      })

      if (
        u.searchParams.has('included[]') ||
        u.searchParams.has('explicitly_included[]')
      ) {
        r.headers.set('Accept', 'application/vnd.api+json')
      }

      if (serviceConfig.getStaticData) {
        json = await serviceConfig.getStaticData(r)
      } else {
        const res = await window.windowFetch(r)
        json = await res.json()
      }

      if (!json.data && !json.pagination) {
        json = {
          data: Array.isArray(json) ? json : [json],
          pagination: { total: 1 }
        }
      }

      data.push(...json.data.map((row) => flattenItem(row, json.included)))
    }
  }

  if (serviceConfig.update) {
    data = serviceConfig.update(data)
  }

  setEndpointData(serviceName, serviceConfig, data)
  return data
}

const getDataRow = async (req, origin, pathname, id) => {
  const [serviceName, serviceConfig] = getServiceConfig(pathname)

  let body
  try {
    body = await getEndpointDataRow(serviceName, id)
  } catch {
    const data = await getData(req, origin, pathname)
    await setEndpointData(serviceName, serviceConfig, data)
    body = data.find((row) => row[serviceConfig.key] === id)
  }

  return body
}

const getDb = () =>
  new Promise((resolve, reject) => {
    const openRequest = indexedDB.open(
      'demo',
      Object.keys(mockedServices).length
    )

    openRequest.onerror = (error) => {
      reject(error)
    }

    openRequest.onsuccess = (event) => {
      const db = event.target.result

      db.onerror = (error) => {
        console.error('Demo db error', error)
      }

      resolve(db)
    }

    openRequest.onupgradeneeded = (event) => {
      const db = event.target.result

      db.onerror = (error) => {
        reject(error)
      }

      for (const name of Object.keys(mockedServices)) {
        if (!db.objectStoreNames.contains(name)) {
          db.createObjectStore(name)
        }
      }
    }
  })

export const getEndpointData = (serviceName) =>
  getDb().then((db) =>
    new Promise((resolve, reject) => {
      const tx = db.transaction(serviceName, 'readwrite')
      tx.onerror = (e) => reject(e.target.error)

      const store = tx.objectStore(serviceName)
      const request = store.getAll()
      request.onerror = (e) => reject(e.target.error)
      request.onsuccess = (e) => {
        const { result } = e.target
        if (!result?.length) {
          reject()
        } else {
          resolve(result)
        }
      }
    }).finally(() => db.close())
  )

export const getEndpointDataRow = (serviceName, id) =>
  getDb().then((db) =>
    new Promise((resolve, reject) => {
      const tx = db.transaction(serviceName, 'readwrite')
      tx.onerror = (e) => reject(e.target.error)

      const store = tx.objectStore(serviceName)
      const request = store.get(id)
      request.onerror = (e) => reject(e.target.error)
      request.onsuccess = (e) => {
        const { result } = e.target
        if (!result) {
          reject(new Error('Not found'))
        } else {
          resolve(result)
        }
      }
    }).finally(() => db.close())
  )

const getEndpointNextKey = (serviceName, key) =>
  getEndpointData(serviceName).then(
    (data) =>
      data.reduce((acc, row) => (acc > row[key] ? acc : row[key]), 0) + 1
  )

const getServiceConfig = (pathname) => {
  const [serviceName, serviceConfig] = Object.entries(mockedServices).find(
    ([, c]) => c.regex.test(pathname)
  )

  if (serviceConfig.alias) {
    return [serviceConfig.alias, mockedServices[serviceConfig.alias]]
  }

  return [serviceName, serviceConfig]
}

export const localFetch = async (req) => {
  const url = new URL(req.url)
  let pathname = url.pathname

  let id
  if (/\d+$/.test(url.pathname)) {
    const parts = url.pathname.split('/')
    id = Number(parts.pop())
    pathname = parts.join('/')
  }

  const [serviceName, serviceConfig] = getServiceConfig(pathname)
  let method = req.method.toUpperCase()

  const idMatch = pathname.match(serviceConfig.regex)
  if (!id && idMatch?.[1]) {
    id = Number(idMatch[1])
  }

  if (serviceName === 'usersettings' && method === 'POST') {
    method = 'PATCH'
  }

  let body
  if (id) {
    body = await getDataRow(req, url.origin, pathname, id)

    if (serviceConfig.transform) {
      body = await serviceConfig.transform(body, id, url)
    }

    switch (method) {
      case 'DELETE':
        await deleteEndpointDataRow(serviceName, id)
        break

      case 'PATCH': {
        const payload = await extractBody(req)
        for (const [key, val] of Object.entries(payload)) {
          body[key] = val
        }
        body.updated_at = getDateString(undefined, { iso8601: true })
        await setEndpointData(serviceName, serviceConfig, [body])
        break
      }

      case 'PUT': {
        body = await extractBody(req)
        body.updated_at = getDateString(undefined, { iso8601: true })
        await setEndpointData(serviceName, serviceConfig, [body])
        break
      }
    }
  } else if (method === 'POST') {
    body = await extractBody(req)

    body[serviceConfig.key] = await getEndpointNextKey(
      serviceName,
      serviceConfig.key
    )
    body.created_at = getDateString(undefined, { iso8601: true })
    body.updated_at = getDateString(undefined, { iso8601: true })

    for (const [key, val] of Object.entries(
      (await serviceConfig.getDefaults?.(body)) || {}
    )) {
      body[key] = val
    }

    await setEndpointData(serviceName, serviceConfig, [body])
  } else {
    let {
      desc,
      limit = 10,
      offset = 0,
      orderby = serviceConfig.key,
      ...filters
    } = Object.fromEntries(url.searchParams)

    desc = desc === 'true'
    limit = Number(limit)
    offset = Number(offset)

    const data = await getData(req, url.origin, pathname)

    if (serviceConfig.transform) {
      body = await serviceConfig.transform(data, id, url)
    } else {
      const paginated = data
        .filter(filterRows(filters))
        .sort(sortAlphabetically({ desc, key: orderby }))

      body = {
        data: paginated.slice(offset, offset + limit),
        pagination: {
          limit,
          offset,
          total: paginated.length
        }
      }
    }
  }

  return new Response(JSON.stringify(body))
}

export const mockedMethods = ['DELETE', 'PATCH', 'POST', 'PUT']

export const mockEndpoint = (url) => {
  const u = new URL(url)
  let pathname = u.pathname

  if (/\d+$/.test(u.pathname)) {
    const parts = u.pathname.split('/')
    parts.pop()
    pathname = parts.join('/')
  }

  if (pathname.endsWith('/')) {
    pathname = pathname.substring(0, pathname.length - 1)
  }

  return Object.values(mockedServices).some((c) => c.regex.test(pathname))
}

export const resetDemo = (callback) =>
  new Promise((resolve, reject) => {
    const req = indexedDB.deleteDatabase('demo')
    req.onblocked = (event) => reject(event)
    req.onerror = (event) => reject(event)
    req.onsuccess = () => resolve(callback?.())
  })

export const setEndpointData = (serviceName, serviceConfig, data) =>
  getDb().then((db) =>
    Promise.all(
      data.map(
        (row) =>
          new Promise((resolve, reject) => {
            const tx = db.transaction(serviceName, 'readwrite')
            tx.oncomplete = () => resolve()
            tx.onerror = (e) => reject(e.target.error)

            const store = tx.objectStore(serviceName)
            const request = store.put(row.rows ?? row, row[serviceConfig.key])
            request.onerror = (e) => reject(e.target.error)
          })
      )
    ).finally(() => db.close())
  )
