import _isObject from 'lodash/isObject'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@beyond/reactstrap-emma'
import Select from '../components/Select'
import useModals from '../hooks/useModals'
import useUser from '../hooks/useUser'

const CustomModal = ({
  actions = [],
  actionArgs,
  body,
  component: Component,
  fromService,
  id,
  isCreatable,
  isFullScreen,
  isValidNewOption,
  modalBodyClasses,
  modalBodyStyles,
  noClose,
  onCancel,
  placeholder,
  size,
  title,
  ...modalProps
}) => {
  const { removeModal } = useModals()
  const { t } = useTranslation()
  const [input, setInput] = useState()
  const { loading } = useUser()

  const handleToggle = () => {
    removeModal(id)
    onCancel?.()
  }

  return (
    <Modal
      backdrop={noClose ? 'static' : undefined}
      className={`shadow${
        isFullScreen ? ' is-fullscreen' : size ? ` modal-${size}` : ''
      }`}
      isOpen
      keyboard={!noClose}
      toggle={handleToggle}
      {...modalProps}
    >
      <ModalHeader close={noClose ? <span /> : undefined} toggle={handleToggle}>
        {title}
      </ModalHeader>

      <ModalBody className={modalBodyClasses} style={modalBodyStyles}>
        {Component ? (
          <Component />
        ) : (
          <>
            <p
              // biome-ignore lint/security/noDangerouslySetInnerHtml:
              dangerouslySetInnerHTML={{ __html: body }}
            />
            {(actionArgs || fromService) && (
              <Select
                autoFocus
                className={fromService && loading > 0 ? 'is-loading' : ''}
                classNamePrefix="modal-dialog"
                formatCreateLabel={(value) => t('misc.input_create', { value })}
                fromService={fromService}
                isCreatable={isCreatable}
                isValidNewOption={isValidNewOption}
                onChange={
                  actions.length > 1
                    ? (value) => setInput(value)
                    : ({ value }) => {
                        actions[0].action(value)
                        removeModal(id)
                      }
                }
                options={actionArgs?.map((arg) =>
                  _isObject(arg) ? arg : { label: arg, value: arg }
                )}
                placeholder={
                  placeholder ??
                  (fromService
                    ? loading > 0
                      ? t('misc.loading')
                      : t('misc.input_placeholder_search')
                    : isCreatable
                      ? t('misc.input_placeholder')
                      : t('misc.please_select'))
                }
                value={input}
              />
            )}
          </>
        )}
      </ModalBody>

      {((actionArgs && actions.length > 1) ||
        (!actionArgs && actions.length > 0)) && (
        <ModalFooter className="justify-content-end">
          {actions
            .filter(({ label }) => label)
            .map(({ action, color, label, ...otherProps }) => (
              <button
                className={`btn btn-${color || 'primary'}`}
                disabled={actionArgs && !input}
                key={label}
                onClick={({ target }) => {
                  target.setAttribute('disabled', '')
                  action?.(actionArgs ? input.value : target.name || label)
                  removeModal(id)
                }}
                type="button"
                {...otherProps}
              >
                {label}
              </button>
            ))}
        </ModalFooter>
      )}
    </Modal>
  )
}

CustomModal.propTypes = {
  actions: PropTypes.array,
  body: PropTypes.string,
  component: PropTypes.elementType,
  actionArgs: PropTypes.array,
  fromService: PropTypes.shape({
    name: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired
  }),
  id: PropTypes.string,
  isCreatable: PropTypes.bool,
  isFullScreen: PropTypes.bool,
  modalBodyClasses: PropTypes.string,
  modalBodyStyles: PropTypes.object,
  noClose: PropTypes.bool,
  onCancel: PropTypes.func,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'lg', 'xl']),
  title: PropTypes.node
}

export default CustomModal
